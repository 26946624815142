import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Termsbreadcreumb from "../components/terms/terms-breadcumbs";

import Termscontent from "../components/terms/terms-content";

// markup
const TermsPage = () => {
  return (
    <div>
      <Seo title="Terms Of Use" />

      <Layout isSticky>
        <section>
          <Termsbreadcreumb />
        </section>

        <section>
          <Termscontent />
        </section>
      </Layout>
    </div>
  );
};

export default TermsPage;
