import React from "react";

import { Link } from "gatsby";

import Calender from "../../assets/images/learn/calender.png";

export default function Termscontent() {
  return (
    <section className="termscontent-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 terms"> Terms Of Use </h4>
            <p className="theme-p-tag">
              {" "}
              This Terms Of Use Agreement (This “Agreement”) Is A Legal
              Agreement Between You (“You” Or “Your”) And Sahaj Marg
              Spirituality Foundation, Non-Profit Corporation Registered In The
              Us, Represented In South Africa By Shri Ram Chandra Mission Npc,
              Its Affiliates, And Each Of Their Respective Successors And
              Assigns (Collectively, The “Institute”) Governing Your Use Of (I)
              Any Workshop, Seminar, Class, Conference, Course Or Other Service
              (Collectively, The “Services”), Offered Or Promoted By The
              Institute Through The Website Located At Heartfulness.Org, Any
              Other Domain, Social Media Accounts Or Other Online Platform Owned
              Or Controlled By The Institute (The “Site”); And (Ii) Any
              Materials, Content Or Other Offline Components Provided By The
              Institute Through The Site (The “Materials”).
            </p>
            <p className="theme-p-tag">
              {" "}
              By Accessing The Site Or Participating In Any Class Or Using Any
              Of The Institute&#39;s Services, You Are Agreeing That You Have
              Read, And Agree To Comply With And Be Bound By, The Terms Of This
              Agreement In Their Entirety Without Limitation Or Qualification
              And All Applicable Laws And Regulations. If You Do Not Agree To Be
              Bound By The Terms And Conditions Of This Agreement, Do Not Access
              The Site, Participate In Any Class, Or Use Any Services, Offered
              By The Institute.
            </p>
            <h4 className="theme-h4 terms"> 1.Minimum Age Requirement </h4>
            <p className="theme-p-tag">
              {" "}
              The Services Are Intended For Those Who Are 16 Years Of Age And
              Older. If You Are Under The Age Of 16, You May Not Use The
              Services Without Providing Verifiable Consent From A Parent Or
              Legal Guardian. In Addition, If Applicable Law Requires That You
              Must Be Older Than 16 To Lawfully Use The Services Without
              Parental Consent Then You Must Be Such Older Age To Use The
              Services. Creating An Account With False Information Is A
              Violation Of Our Terms, Including Accounts Registered On Behalf Of
              Others Or Persons Under The Age Of 16.
            </p>
            <h4 className="theme-h4 terms">
              {" "}
              2. Authorization And Release To Use Your Personal Information{" "}
            </h4>
            <p className="theme-p-tag">
              {" "}
              If You Participate In A Workshop Or Otherwise Use The Institute’s
              Services, You Hereby Grant The Institute The Irrevocable And
              Perpetual Right To (I) Record Your Name, Likeness And/Or Voice
              Relating To Your Participation In The Services (Collectively, The
              “Released Subject Matter”) Which Includes, Without Limitation,
              Photographs, Pictures, Portraits, Moving Pictures, Recorded Voice,
              Recorded Video, And Stills (The “Images”), And To Edit Such
              Released Subject Matter At The Institute’s Discretion; And (Ii)
              Publish, Reproduce, Copyright, Exhibit, Display, Distribute, And
              Otherwise Use The Images, Or Any Portion Thereof, In All Forms And
              Media Including Composite Or Modified Representations For Any
              Lawful Purpose That The Institute Deems Appropriate, Including
              Advertising, Trade, Exhibition, Illustration, Promotion,
              Publicity, Advertising, Electronic Publication And Any Commercial
              Purpose. You Acknowledge And Agree That You Are Not Owed And Will
              Not Be Owed Any Consideration Or Royalty For Such Use. You
              Understand And Agree That The Institute Is Under No Obligation To
              Make Any Use Of The Images Or The Rights Granted Herein. You
              Expressly Release The Institute And Its Agents, Employees,
              Licensees, Volunteers, Successors And Assigns (And All Persons
              Acting Under Any Of Their Permission Or Authority) From And
              Against Any And All Claims Arising In Connection With Any Use Of
              The Released Subject Matter Or The Exercise Of The Rights Granted
              Herein Which You Have Or May Have For Defamation, Invasion Of
              Privacy, , Copyright Or Trademark Infringement, Rights Of
              Publicity, Any Other Personal Or Proprietary Right, Or Any Other
              Cause Of Action Arising Out Of The Production, Distribution,
              Broadcast, Or Exhibition Of The Released Subject Matter.{" "}
            </p>
            <h4 className="theme-h4 terms"> 3.Prohibition On Recording </h4>
            <p className="theme-p-tag">
              {" "}
              Except As Otherwise Permitted In Writing By The Institute, No
              Materials Or Information, Regardless Of Form (Including, Without
              Limitation, Oral, Documentary, Photographic, Videographic, And
              Electronic), Provided By The Institute To You Through The Services
              Or Otherwise, Including At Any Workshop, Seminar, Class,
              Conference Or Course (Collectively, “Content”) May Be Recorded,
              Copied, Reproduced, Republished, Uploaded, Posted, Transmitted, Or
              Distributed In Any Way. You May Use The Content For
              Non-Commercial, Personal Use Only. You May Not Use, Distribute,
              Modify, Transmit, Or Post The Content, Including Any Text, Images,
              Audio, Or Video, Without The Institute’s Express Prior Written
              Consent. All Rights In And To The Content Are Reserved To The
              Institute.{" "}
            </p>
            <h4 className="theme-h4 terms"> 4.License Grant </h4>
            <p className="theme-p-tag">
              {" "}
              Subject To The Terms And Conditions Of This Agreement, The
              Institute Hereby Grants You A Limited, Non-Exclusive,
              Non-Transferable, License To Access The Site And Download The
              Materials Solely For Your Own Use. The License Granted Herein Is
              Expressly Conditioned On Your Continued Compliance With The Terms
              And Conditions Of This Agreement.
            </p>
            <p className="theme-p-tag">
              {" "}
              This Agreement Permits You To Use The Site For Your Personal,
              Non-Commercial Use Only. You Must Not Reproduce, Distribute,
              Modify, Create Derivative Works Of, Publicly Display, Publicly
              Perform, Republish, Download, Store, Or Transmit Any Of The
              Material On The Site, Except As Follows:
            </p>
            <p className="theme-p-tag">
              {" "}
              ● You May Temporarily Store Copies Of Such Materials On Your
              Computer Incidental To Your Accessing And Viewing Those Materials;
            </p>
            <p className="theme-p-tag">
              {" "}
              ● You May Store Files That Are Automatically Cached By Your Web
              Browser For Display Enhancement Purposes;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● You May Print Or Download One Copy Of A Reasonable Number Of
              Pages Of The Site For Your Own Personal, Non-Commercial Use And
              Not For Further Reproduction, Publication, Or Distribution; And{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● You May Download A Single Copy Of The Materials To Your Computer
              Or Mobile Device Solely For Your Own Personal, Non-Commercial Use.
            </p>
            <p className="theme-p-tag"> You Must Not: </p>
            <p className="theme-p-tag">
              {" "}
              ● Modify Copies Of Any Materials From This Site;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Use Any Illustrations, Photographs, Video Or Audio Sequences, Or
              Any Graphics On The Site Separately From The Accompanying Text; Or
            </p>
            <p className="theme-p-tag">
              {" "}
              ● Delete Or Alter Any Copyright, Trademark, Or Other Proprietary
              Rights Notices From Copies Of Materials From This Site.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              If You Wish To Make Any Use Of Material Or The Site Other Than
              That Set Out In This Section, Please Address Your Request To The
              Contact Information Provided Below.
            </p>
            <h4 className="theme-h4 terms"> 5.Restrictions On License Grant </h4>
            <p className="theme-p-tag">
              {" "}
              Your Use Of The Site Is Limited To The Scope Of The License
              Granted In This Agreement And This Agreement Does Not Permit You
              To Use The Site Other Than As Provided Herein. You Acknowledge
              That The Site Constitutes A Valuable Asset Of The Institute. You
              Acknowledge And Agree That Except As Otherwise Authorized Under
              This Agreement Or Otherwise Specified In Writing Between The
              Parties:{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>a.</b> You Shall Not License, Sublicense, Sell, Resell, Rent, Lease,
              Transfer, Assign, Distribute, Grant A Security Interest In, Or
              Otherwise Transfer Any Rights To, Or Commercially Exploit, The
              Site Or The Materials Or Use The Site To Run, Or As Part Of, A
              Service Bureau, Outsourced, Or Managed Services Arrangement;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>b.</b> You Shall Not Copy, Reproduce, Republish, Upload, Post,
              Transmit Or Distribute The Site Or The Materials In Any Way;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
            <b>c.</b> You Shall Not Modify, Translate, Alter, Adapt, Decompile,
              Disassemble (Except To The Extent Applicable Laws Specifically
              Prohibit Such Restriction), Reproduce, Distribute Or Display, Or
              Create Derivative Works, Compilations Or Collective Works Based On
              The Site Or The Materials;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
            <b>d.</b> You Shall Not Knowingly Or Negligently Permit Other Individuals
              Or Entities To Use Or Copy The Site, Or Create Internet “Links” To
              The Site Or “Frame” Or “Mirror” The Site On Any Other Server Or
              Wireless Or Internet-Based Device;
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>e.</b> You Shall Not Access The Site For Any Reason, Including Without
              Limitation, In Order To (a) Build A Competitive Product Or
              Service, (b) Build A Product Using Similar Ideas, Features,
              Functions Or Graphics Of The Site, (c) Copy Any Ideas, Features,
              Functions Or Graphics Of The Site, (d) Monitor Its Availability,
              Performance Or Functionality, Or (e) For Any Other Benchmarking Or
              Competitive Purposes;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
            <b>g.</b> You Shall Not Attempt To Use Or Gain Unauthorized Access To
              Data, Accounts, Hosts, Systems Or Networks Of The Institute Or Any
              Of Its Customers Or Suppliers, Or Those Of Any Other Party; Breach
              The Security Of Another User Or System, Or Attempt To Circumvent
              The User Authentication Or Security Of Any Host, Network, Or
              Account, Including, Without Limitation, Accessing Data Not
              Intended For You Or Logging Into Or Making Use Of A Server Or
              Account You Are Not Expressly Authorized To Access;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>h.</b> You Shall Not Attempt To Probe, Scan Or Test The Vulnerability
              Of A System, Account Or Network Of The Institute Or Any Of Its
              Customers Or Suppliers;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
             <b>i.</b> You Shall Not Interfere, Or Any Attempt To Interfere, With
              Service To Any User, Host Or Network Including, Without
              Limitation, Mail-Bombing, Flooding, And Attempting To Deliberately
              Overload The System Or Distribute Programs That “Crack,” Or Make
              Unauthorized Changes To, The Software Which Operates The Site;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
             <b>j.</b> You Shall Not Forge Any Tcp-Ip Packet Header Or Any Part Of Any
              Header Information, Falsify, Alter Or Remove Address Information
              Or Other Modification Of E-Mail Headers; Collect Responses From
              Unsolicited Bulk Messages, Falsify References To The Institute Or
              The Site, By Name Or Other Identifier, In Messages; Impersonate
              Any Person Or Entity, Engage In Sender Address Falsification,
              Forge Anyone Else’s Digital Or Manual Signature, Or Perform Any
              Other Similar Fraudulent Activity;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>k.</b> You Shall Not Restrict, Inhibit, Or Otherwise Interfere With
              The Ability Of Any Other Person, Regardless Of Intent, Purpose Or
              Knowledge, To Use Or Enjoy The Site (Except For Tools For Safety
              And Security Functions), Including, Without Limitation, Posting Or
              Transmitting Any Information Or Software Which Contains A Worm,
              Virus, Or Other Harmful Feature, Or Generating Levels Of Traffic
              Sufficient To Impede Others’ Ability To Use, Send, Or Retrieve
              Information;
            </p>
            <p className="theme-p-tag">
              {" "}
             <b>l.</b> You Shall Not Restrict, Inhibit, Interfere With, Or Otherwise
              Disrupt Or Cause A Performance Degradation, Regardless Of Intent,
              Purpose Or Knowledge, To The Site Or Any Of The Institute’s Host,
              Server, Backbone Network, Node Or Service, Or Otherwise Cause A
              Performance Degradation To The Institute’s Facilities Used To
              Deliver The Site; And{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
             <b>m.</b> You Shall Not Create Or Use Any Program, Tags, Markers, Bots,
              Mousetraps, Highjackers Or Other Similar Computer Routines Or
              Sub-Routines To Automatically Access Or Manipulate The Site.
              Because Of The Difficulty Associated With Quantifying Damages, In
              Addition To Any Other Damages To Which The Institute May Be
              Entitled, If Actual Damages Cannot Be Reasonably Calculated Then
              You Agree To Pay The Institute Liquidated Damages Of $250 For Each
              Violation Of This Section 5 Or The Maximum Liquidated Damages
              Permitted Under Law, Whichever Is Greater; And You Agree To Pay
              The Institute’s Actual Damages, To The Extent Such Actual Damages
              Can Be Reasonably Calculated.
            </p>
            <h4 className="theme-h4 terms"> 6.Privacy </h4>
            <p className="theme-p-tag">
              {" "}
              Information Collected By The Institute Is Subject To The Privacy
              Policy Available{" "}
              <Link to="/privacy-policy/" className="btn link-color btn-pad0">
                Here
              </Link>{" "}
              By Using The Site, You Consent To All Actions Taken By Us With
              Respect To Your Information In Compliance With The Privacy Policy.{" "}
            </p>
            <h4 className="theme-h4 terms"> 7.User Content </h4>
            <p className="theme-p-tag">
              {" "}
              You Represent And Warrant That Any Material You Post To The Site
              (Collectively, “User Content”) Does Not Infringe Another Party’s
              Proprietary Rights, Including Copyright, Trademark, Patent, Right
              Of Publicity Or Other Intellectual Property Right, Or Any
              Applicable National Or Local Law That May Apply Regarding The
              Posting Of Material. You Also Agree That Any User Content Will Not
              Defame, Or Invade The Right Of Privacy Or Publicity Of Any Other
              Person. If You Submit Any User Content To The Site (Presentations,
              Instructional Videos, Questions, Comments Or Any Other Content)
              You Understand And Agree That It May Be Used By The Institute For
              Any Reason Throughout The World Without Requirement Or Consent,
              And That The Institute May Edit Your User Content. You Hereby
              Grant The Institute A Worldwide, Non-Exclusive, Royalty Free And
              Perpetual, Non-Revocable License To Use Your User Content, In
              Whole Or In Part, In Whatever Way The Institute Deems Appropriate
              Throughout The World, In Any Media Platforms Available Now Or
              Developed In The Future. If You Post User Content In A Way That
              Infringes On Another Party’s Intellectual Property Rights, Or This
              Agreement, You Acknowledge That The Institute May Remove The
              Posting And That You Agree To Indemnify The Institute Based On The
              Indemnification Provided Below.
            </p>
            <h4 className="theme-h4 terms"> 8.Intellectual Property Ownership </h4>
            <p className="theme-p-tag">
              {" "}
              All Right, Title And Interest In The Site, The Materials And The
              User Content (Other Than Your Own User Content), Including
              Technology And Trade Secrets Embodied Therein And Any Custom
              Developments Created Or Provided In Connection With Or Related To
              This Agreement, Including All Copyrights, Patents, Trade Secrets,
              Trade Dress And Other Proprietary Rights, And Any Derivative Works
              Thereof, Shall Belong Solely And Exclusively To The Institute Or
              Its Licensors, And You Shall Have No Rights Whatsoever In Any Of
              The Foregoing Other Than The Limited Rights Granted Herein. You
              Acknowledge That Information On The Site May Constitute A Valuable
              Trade Secret And/Or Is The Confidential Information Of The
              Institute Or Its Licensors. Nothing In This Agreement Or Otherwise
              Will Be Deemed To Grant To You An Ownership Interest In The Site,
              The Materials Or The User Content (Other Than Your Own User
              Content), In Whole Or In Part. All Content On The Site, Such As
              Text, Graphics, Logos, Button Icons, Images, Audio Clips,
              Information, Data, Forms, Photographs, Graphs, Videos, Typefaces,
              Graphics, Music, Sounds, And Other Material, And Software (The
              “Works”) Are The Property Of The Institute Or Its Content
              Suppliers And Is Protected By Copyrights, Trademarks, Trade
              Secrets, Or Other Proprietary Rights And These Rights Are Valid
              And Protected In All Forms, Media And Technologies Existing Now Or
              Hereinafter Developed. All Works Are Copyrighted As Individual
              Works And As A Collective Work Under The U.S. Copyright Laws (17
              U.S.C. Section 101, Et. Seq.) And International Treaty Provisions,
              And The Institute Owns A Copyright In The Selection, Coordination,
              Arrangement And Enhancement Thereof. You May Not Modify, Remove,
              Delete, Augment, Add To, Publish, Transmit, Adapt, Translate,
              Participate In The Transfer Or Sale Of, Create Derivative Works
              From, Or In Any Way Exploit Any Of The Works, In Whole Or In Part.
              Any Use Other Than As Contemplated Herein, Including The
              Reproduction, Modification, Distribution, Transmission,
              Adaptations, Translation, Republication, Display, Or Performance,
              Of The Works, Except As Specifically Permitted Herein, Is Strictly
              Prohibited. You Understand And Acknowledge That Unauthorized
              Disclosure, Use Or Copying Of The Proprietary Products And
              Services Provided Pursuant To This Agreement May Cause The
              Institute And Its Licensors Irreparable Injury, Which May Not Be
              Remedied At Law, And You Agree That The Institute And Its
              Licensors’ Remedies For Breach Of This Agreement May Be In Equity
              By Way Of Injunctive Or Other Equitable Relief.{" "}
            </p>
            <h4 className="theme-h4 terms"> 9.Trademarks </h4>
            <p className="theme-p-tag">
              {" "}
              The Sahaj Marg Spirituality Foundation (Smsf) India, Logo And The
              Related Names, Design Marks, Product Names, Feature Names And
              Related Logos (Collectively, The “Smsf Marks”) Are Trademarks Of
              Sahaj Marg Spirituality Foundation, Inc, A Texas Non-Profit
              Corporation, And Are Used By The Institute Under License. The
              Sahaj Marg Meditation Name, Logo And The Related Names, Design
              Marks, Product Names, Feature Names And Related Logos
              (Collectively, The “Srcm Marks”) Are Trademarks Of Shri Ram
              Chandra Mission, A California Nonprofit Corporation, And Are Used
              By The Institute Under License. Neither The Smsf Marks Nor The
              Srcm Marks May Be Used, Copied Or Imitated, In Whole Or In Part,
              Without The Express Prior Written Consent Smsf Or Srcm, As
              Applicable. In Addition, The Look And Feel Of The Site (Including
              All Page Headers, Custom Graphics, Button Icons, And Scripts)
              Constitutes The Service Mark, Trademark And/Or Trade Dress Of The
              Institute And May Not Be Copied Imitated Or Used, In Whole Or In
              Part, Without The Express Prior Written Consent Of The Institute.{" "}
            </p>
            <h4 className="theme-h4 terms"> 10. License To Third Party Content </h4>
            <p className="theme-p-tag">
              {" "}
              The Institute Displays Certain Content Via The Youtube Embedded
              Player Accessible Through The Site. Any Content That Is Provided
              By Youtube Is Subject To Their Terms Of Use And License
              Requirements. Please Review Their Terms Of Use That Apply For
              License And Usage Terms. Additionally, The Site May Use Youtube
              Api Services To Allow Users To View Videos From Youtube.Com In
              Order To Use This App, You Need To Consent To Be Bound To
              Youtube’s Terms Of Service{" "}
              <Link
                className="link-color"
                to="https://www.youtube.com/t/terms"
                target="blank"
                rel="noreferrer"
              >
                https://www.youtube.com/t/terms
              </Link>
            </p>
            <h4 className="theme-h4 terms">
              {" "}
              11.Term; Termination; Suspension Of Access{" "}
            </h4>
            <p className="theme-p-tag">
              {" "}
              The Term Of This Agreement Commences The First Time You Access The
              Site And Continues Through Any Subsequent Use Of The Site
              Thereafter. Any Unauthorized Use Of The Site Will Be Deemed A
              Material Breach Of This Agreement. The Institute, In Its Sole
              Discretion, May Terminate Your Password, Account Or Use Of The
              Site If You Breach Or Otherwise Fail To Comply With This
              Agreement.{" "}
            </p>
            <h4 className="theme-h4 terms"> 12. Medical Disclaimer </h4>
            <p className="theme-p-tag">
              {" "}
              The Institute Makes Its Services Available At No Charge To All
              Seekers, Including Individuals Who May Be Dealing With Personal
              Health Issues. The Services Provided By The Institute Are Not A
              Substitute For Professional Medical Advice, Diagnosis Or
              Treatment. All Information Provided By The Institute Is For
              Informational Purposes Only, And You Hereby Assume All Of The Risk
              In Participating In Any Of The Institute’s Services. The Institute
              Strongly Advises That You Seek The Advice Of Your Physician Or
              Other Qualified Health Provider With Any Questions You May Have
              Regarding A Medical Condition. Never Disregard Professional
              Medical Advice Because Of Information Received From The Institute.
            </p>
            <p className="theme-p-tag">
              If You Believe You May Have A Medical Emergency, Call Your Doctor,
              Go To Your Local Emergency Room, Or Call The Local Emergency Phone
              Number Supported By Governmental Authorities (Example 911 In The
              United States) Immediately.
            </p>
            <p className="theme-p-tag">
              {" "}
              On Behalf Of Your Executors, Administrators, Heirs, Next Of Kin,
              Successors, And Assigns, You Hereby: (a) Waive, Release, And
              Discharge From Any And All Liability For Your Death, Disability,
              Personal Injury, Property Damage, Property Theft, Or Actions Of
              Any Kind Which May Hereafter Occur To You, The Following Entities
              Or Persons: The Institute, Its Affiliated Entities, Its And Their
              Direct And Indirect Owners, And Each Of Its And Their Directors,
              Officers, Employees, Volunteers, Representatives, And Agents; And
              (B) Indemnify, Hold Harmless, And Promise Not To Sue The Entities
              Or Persons Mentioned Above From Any And All Liabilities Or Claims
              Made As A Result Of Participation In Any Of The Services. This
              Release Shall Be Construed Broadly To Provide A Release And Waiver
              To The Maximum Extent Permissible Under Applicable Law.{" "}
            </p>
            <h4 className="theme-h4 terms"> 13. Disclaimer Of Warranties </h4>
            <p className="theme-p-tag">
              {" "}
              Except As Otherwise Expressly Provided In This Agreement, (a) The
              Site Is Provided “As-Is” And “With All Faults”, And, To The
              Maximum Extent Permitted By Applicable Law, The Institute,
              Including Its Affiliates, Subsidiaries, Licensors, Subcontractors,
              Distributors, Services Partners, Agents And Marketing Partners)
              And Each Of Their Respective Employees, Directors And Officers
              (Collectively, The “Institute Parties”) Disclaim All
              Representations, Warranties And Conditions Of Any Kind, Express Or
              Implied, Regarding The Site, Or Otherwise Relating To This
              Agreement, Including Non-Infringement And Accuracy; (b) Neither
              The Institute Nor Any Institute Party Warrants That The Site Is Or
              Will Be Secure, Accurate, Complete, Uninterrupted, Without Error,
              Or Free Of Viruses, Worms, Other Harmful Components, Or Other
              Program Limitations; (c) You Assume The Entire Cost Of All
              Necessary Servicing, Repair, Or Correction Of Problems Caused By
              Viruses Or Other Harmful Components, Unless Such Errors Or Viruses
              Are The Direct Result Of The Institute’s Gross Negligence Or
              Willful Misconduct; (d) The Institute And The Institute Parties,
              Jointly And Severally, Disclaim And Make No Warranties Or
              Representations As To The Accuracy, Quality, Reliability,
              Suitability, Completeness, Usefulness, Or Effectiveness Of The
              Information Obtained, Generated Or Otherwise Received By You From
              Accessing And/Or Using The Site Or Otherwise Relating To This
              Agreement, And (e) Use Of The Site Is Entirely At Your Own Risk
              And Neither The Institute Nor Any Of The Institute Parties Shall
              Have Any Liability Or Responsibility Therefor.
            </p>
            <h4 className="theme-h4 terms"> 14. Limitation Of Liability </h4>
            <p className="theme-p-tag">
              {" "}
              Notwithstanding Anything To The Contrary In This Agreement, In No
              Event Whatsoever Shall The Institute Be Liable For Any Indirect,
              Special, Incidental, Punitive Or Consequential Damages, Including
              But Not Limited To Loss Of Profits, Lost Time Or Good Will, Even
              If The Institute Has Been Advised Of The Possibility Of Such
              Damages, Whether In Contract, Delict (Including Negligence),
              Strict Liability Or Otherwise. The Institute Shall Not Be Liable
              For Any Claims Against You By Third Parties. In No Event Shall The
              Maximum Cumulative Liability Of The Institute In Connection With
              The Site And/Or This Agreement, Regardless Of The Form Of Action,
              Exceed $100. No Action, Regardless Of Form, Arising From Or
              Pertaining To The Site May Be Brought By You More Than One (1)
              Year After Such Action Has Accrued. Certain States And/Or
              Jurisdictions Do Not Allow The Exclusion Of Implied Warranties Or
              Limitation Of Liability For Incidental, Consequential Or Certain
              Other Types Of Damages, So The Exclusions Set Forth Above May Not
              Apply To You.
            </p>
            <h4 className="theme-h4 terms"> 15. Release </h4>
            <p className="theme-p-tag">
              {" "}
              You Knowingly, And Voluntarily Do Hereby Indemnify, Release,
              Acquit, Waive, And Covenant Not To Sue The Institute And The
              Institute Parties From And Against Any And All Liabilities, Costs
              And Expenses (Including Without Limitations, Any Reasonable Fees
              And Expenses Of Its Attorneys And Consultants) Relating To Or
              Arising Out Of Any Claims, Demands Or Causes Of Action Of Every
              Kind And Character Arising Out Of Or Relating To Your Posting Of
              User Content And Your Use Of The Information And Materials Offered
              By The Institute Through The Site.
            </p>
            <h4 className="theme-h4 terms"> 16.Account Access </h4>
            <p className="theme-p-tag">
              {" "}
              If Use Of All Or Any Part Of The Site Is Contingent On You And
              Your Users Accessing An “Account” And/Or Inserting A
              “User-Identification” And/Or “Password”, You Agree That You Will
              Be Solely Responsible For The User-Ids And Passwords That Are
              Provided To You (As Such Passwords May Be Changed From Time To
              Time In Accordance With Features Of The Site) To Log-In To The
              Password Protected Site. If Non-Authorized Individuals Have Access
              To Your Systems Or To Your Users’ User-Id And Password, They May
              Be Able To Use The Site. You Shall Keep Any Correspondence You
              Receive Relating To Or Through The Use Of The Site (Including, But
              Not Limited To, Your User-Id, Passwords, And Other Registration Or
              Sign-In Information) Confidential And In A Safe Place And Not
              Disclose It To Any Third Party. You Will Be Responsible And Liable
              For All Communications And Actions That Take Place Through The Use
              Of Your User-Ids, Including Without Limitation, Any Actions That
              Occur Without Your Authorization. Accordingly, It Is Your
              Responsibility To Take Appropriate Actions Immediately If Any
              Password Has Been Stolen, Leaked, Compromised Or Otherwise Used
              Without Proper Consent.{" "}
            </p>
            <h4 className="theme-h4 terms"> 17. Modification To Terms </h4>
            <p className="theme-p-tag">
              {" "}
              The Institute May Revise And Update This Agreement From Time To
              Time In Its Sole Discretion. All Changes Are Effective Immediately
              When Posted, And Apply To All Access To And Use Of The Site
              Thereafter. However, Any Changes To The Dispute Resolution
              Provisions Set Out Below Will Not Apply To Any Disputes For Which
              The Parties Have Actual Notice On Or Before The Date The Change Is
              Posted On The Site. Your Continued Use Of The Site Following The
              Posting Of Revised Agreement Means That You Accept And Agree To
              The Changes. You Are Expected To Check This Page From Time To Time
              So You Are Aware Of Any Changes, As They Are Binding On You.
            </p>
            <h4 className="theme-h4 terms"> 18. Dispute Resolution </h4>
            <p className="theme-p-tag">
              {" "}
              Any Dispute Relating In Any Way To Your Use Of The Site Shall Be
              Submitted To Confidential Arbitration In The Republic Of South
              Africa, Except That, To The Extent You Have In Any Manner Violated
              Or Threatened To Violate The Institute’s Intellectual Property
              Rights, The Institute May Seek Appropriate Relief In Any Court,
              And You Consent To Exclusive Jurisdiction Of Such Court.
              Arbitration Under This Agreement Shall Be Conducted Under The
              Rules Then Prevailing Of The Relevant Arbitration Body. The
              Arbitration Proceedings Shall Be Conducted In English. The
              Arbitrator’s Award Shall Be Binding And May Be Entered As A
              Judgment In Any Court Of Competent Jurisdiction. To The Fullest
              Extent Permitted By Applicable Law, No Arbitration Under This
              Agreement Shall Be Joined To An Arbitration Involving Any Other
              Party Subject To This Agreement, Whether Through Class Arbitration
              Proceedings Or Otherwise.
            </p>
            <h4 className="theme-h4 terms"> 19. Digital Millennium Copyright Act </h4>
            <p className="theme-p-tag">
              {" "}
              The Sahaj Marg Spirituality Foundation, Being The Non-Profit
              Corporation Registered In The Us Has Adopted The Following Policy
              Towards Copyright Infringement In Accordance With The Digital
              Millennium Copyright Act (The “Dmca”). If You Believe Any User
              Content Or The Institute’s Content Infringes Upon Your
              Intellectual Property Rights, Please Submit A Notification
              Alleging Such Infringement (“Dmca Takedown Notice”) Including The
              Following:
            </p>
            <p className="theme-p-tag">
              {" "}
              • A Physical Or Electronic Signature Of A Person Authorized To Act
              On Behalf Of The Owner Of An Exclusive Right That Is Allegedly
              Infringed;
            </p>
            <p className="theme-p-tag">
              {" "}
              • Identification Of The Copyrighted Work Claimed To Have Been
              Infringed, Or, If Multiple Copyrighted Works At A Single Online
              Site Are Covered By A Single Notification, A Representative List
              Of Such Works;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              • Identification Of The Material Claimed To Be Infringing Or To Be
              The Subject Of Infringing Activity And That Is To Be Removed Or
              Access Disabled And Information Reasonably Sufficient To Permit
              The Service Provider To Locate The Material;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              • Information Reasonably Sufficient To Permit The Service Provider
              To Contact You, Such As An Address, Telephone Number, And, If
              Available, An Electronic Mail;{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              • A Statement That You Have A Good Faith Belief That Use Of The
              Material In The Manner Complained Of Is Not Authorized By The
              Copyright Owner, Its Agent, Or The Law; And{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              A Statement That, Under Penalty Of Perjury, The Information In The
              Notification Is Accurate And You Are Authorized To Act On Behalf
              Of The Owner Of The Exclusive Right That Is Allegedly Infringed.
              Any Dmca Takedown Notices Should Be Sent To:
              <a className="link-color" href="mailto:privacy@heartfulness.org">
                privacy@heartfulness.org
              </a>
            </p>
            <h4 className="theme-h4 terms"> 20. General </h4>
            <p className="theme-p-tag">
              {" "}
              <b>a.</b> This Agreement Shall Be Governed By The Relevant Laws In The
              Republic Of South Africa, Without Regard To The Choice Or
              Conflicts Of Law Provisions Of Any Jurisdiction Or The United
              Nations Convention On The International Sale Of Goods, And Any
              Disputes, Actions, Claims Or Causes Of Action Arising Out Of Or In
              Connection With This Agreement Or The Site, With The Exception Of
              Claims For Injunctive Relief, Shall Be Subject To The Jurisdiction
              Of The Courts Located In The Republic Of South Africa.{" "}
            </p>
            <p className="theme-p-tag ">
            <b>b.</b> If Any Provision Of This Agreement Is Held By A Court Of
              Competent Jurisdiction To Be Invalid Or Unenforceable, Then Such
              Provisions Shall Be Construed, As Nearly As Possible, To Reflect
              The Intentions Of The Invalid Or Unenforceable Provisions, With
              All Other Provisions Remaining In Full Force And Effect.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>c.</b> It May Be Necessary For The Institute To Perform Scheduled Or
              Unscheduled Repairs, Maintenance, Or Upgrades And Such Activities
              May Temporarily Degrade The Quality Of The Site Or Result In A
              Partial Or Complete Outage Of The Site. The Institute Provides No
              Assurance That You Will Receive Advance Notification Of Such
              Activities Or That Access To The Site Will Be Uninterrupted Or
              Error-Free.{" "}
            </p>
            <p className="theme-p-tag">
             <b>d.</b> No Joint Venture, Partnership, Employment, Or Agency
              Relationship Exists Between You And The Institute As A Result Of
              This Agreement Or Use Of The Site. The Failure Of The Institute To
              Enforce Any Right Or Provision In This Agreement Shall Not
              Constitute A Waiver Of Such Right Or Provision Unless Acknowledged
              And Agreed To By The Institute In Writing.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>e.</b> If You Have Not Entered Into Another Agreement With The
              Institute Regarding The Subject Matter Contained Herein, Then This
              Agreement Comprises The Entire Agreement Between You And The
              Institute And Supersedes All Prior Or Contemporaneous
              Negotiations, Discussions Or Agreements, Whether Written Or Oral,
              Between The Parties Regarding Such Subject Matter. However, If You
              And The Institute Have Entered Into Another Agreement Regarding
              The Subject Matter Set Forth Herein That Is A Written And Signed
              Agreement Between You And The Institute, Then This Agreement
              Should Be Read And Interpreted In Conjunction With Such Agreement
              And, In The Event Of A Conflict Between This Agreement And A
              Written, Signed Agreement Between The Parties, The Written, Signed
              Agreement Shall Govern And Control.
            </p>
            <p className="theme-p-tag">
              {" "}
              <b>f.</b> Reference To Any Political Activity, Commercial Or
              Non-Commercial Product, Process, Or Service By Trade Name,
              Trademark, Manufacturer Or Otherwise Does Not Constitute Or Imply
              An Endorsement Or Recommendation By The Institute.
            </p>
            <h4 className="theme-h4 terms"> Questions Or Additional Information: </h4>
            <p className="theme-p-tag">
              {" "}
              If You Have Questions Regarding This Agreement Or Wish To Obtain
              Additional Information, Please Send An E-Mail To 

                 <a className="link-color mx-2" href="mailto:privacy@heartfulness.org">
                  privacy@heartfulness.org
              </a>
            </p>
            <div>
                  <img src={Calender} alt="Universal Prayer" style={{
                    position: "absolute", width: "calc(2.25rem + 24px)"
                    , transition: "color .2s"
                  }} />
                <p className="col-md-8 pt-4 px-3 mx-5 theme-p-tag">Last updated: April, 2021</p>
                  </div>
          </div>
        </div>
      </div>
    </section>
  );
}
